
import './App.css';
import './index.css';
import Routing from './Routing/Routing';

function App() {
  return (
    <div className='' style={{  fontFamily: 'Almarai' }}>
      <Routing />
     
      <div className='w-screen p-6'>
      </div>

    </div>
  );
}

export default App;



