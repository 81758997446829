import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import UserdataAdded from './Pages/UserdataAdded';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter >
      
   
  <Provider store={store}>
    {/* <UserdataAdded /> */}
  </Provider>,
 
    <App />
    </BrowserRouter>
);


reportWebVitals();
